import React, { Component } from 'react'
import { navigate }         from 'gatsby'
import {
    map,
    reduce
}                           from 'lodash-es'
import moment               from 'moment'
import cx                   from 'utils/classnames'
import {
    Button,
    Container,
    Col,
    OverlayTrigger,
    Tooltip,
    Row,
}                           from 'react-bootstrap'
import {
    FaClock,
    FaCcVisa,
    FaCcMastercard,
    FaArrowLeft,
    FaQuestionCircle,
}                           from 'react-icons/fa'
import Checkbox             from 'entrada-ui/Checkbox'
import Icon                 from 'entrada-ui/Icon'
import TextFieldComp        from 'entrada-ui/TextField'
import Typography           from 'entrada-ui/Typography'
import { FareCodeToText }   from 'components/BookingWizard'
import CartHandler          from 'components/CartHandler'
import Guarantee            from 'components/Checkout/Guarantee'
import CheckoutTerms        from 'components/Checkout/CheckoutTerms'
import Link                 from 'components/Link'
import TourCards            from 'components/TourCards'
import styles               from './styles.module.scss'

const TourCard = TourCards.CardWp

const ConfirmItem = (props) => {
    const {
        bookingItemRef,
        tourName,
        departureDate,
        tourDurationHours,
        tourDurationMinutes,
        cardImage,
        pickup,
        dropoff,
        fares,
        total
    } = props

    const passengersComposition = reduce(
        fares,
        (acc, numberOfPassengers, fareCode) => {
            // - Generate base price description based on the composition and price
            acc.push(
                `${numberOfPassengers} ${FareCodeToText(fareCode, numberOfPassengers > 1)}`
            )

            return acc
        },
        []
    )

    return (
        <div className={styles.item}>
            <div className={styles.itemLeft}>
                <div className={styles.calendar}>
                    {moment(departureDate).format('MMMM')} <br />
                    <b className={styles.calendarDate}>{moment(departureDate).format('DD')}</b> <br />
                    {moment(departureDate).format('YYYY')}
                </div>
            </div>

            <div  className={styles.itemRight}>
                <Typography className="mb-2 mt-0" variant="h4">
                    {tourName}
                </Typography>

                <p className='mb-0'>{moment(departureDate).format('DD MMM, YYYY')}</p>
                <p className='mb-0'>{passengersComposition.join(', ')}</p>
                {pickup &&
                <p className='mb-0'>Departs: {pickup.time} at {pickup.description}</p>
                }
                {dropoff &&
                <p className='mb-0'>Return: {dropoff.time} at {dropoff.description}</p>
                }

                <div className={styles.confirmed}>
                    Confirmed
                </div>
            </div>
        </div>
    )
}

const Confirmation = ({
    pageContext: {
        data: {
            acf: {
                promoted_tours
            }
        }
    }
}) => {
    const context = React.useContext(CartHandler.Context)
    const { confirmData } = context

    if(typeof window === 'undefined') {
        return <></>
    }

    if(!confirmData) {
        navigate('/')

        return <></>
    }

    const {
        bookingReference,
        cartItems,
        cost // total
    } = confirmData

    // Prepare GTM dataLayer
    let gtmItems = [] 
    gtmItems = cartItems.map(item => {
        const productInfo = {
            productName: item.tourName,
            productID: item.id
        }

        let fares = []
        if (item.fares) {
            fares = Object.entries(item.fares).map(fare => {
                return {
                    type: fare[0].toLowerCase(),
                    quantity: fare[1],
                    cost: 0.00 // @TODO: add individual fare cost (this will require further dev time)
                }
            })
        }

        let options = []
        if (item.options) {
            options = Object.entries(item.options).map(([key, option]) => {
                return {
                    name: option.id, // @TODO: add option.description (this will require further dev time)
                    id: option.id,
                    total: option.total
                }
            })
        }

        return {
            productInfo: productInfo,
            fares: fares,
            options: options
        }
    })

    const gtmDL = {
        event: 'purchase',
        transaction: {
            transactionID: bookingReference,
            total: {
                transactionTotal: cost,
                shipping: 0.00, // Not using 'shipping' at the moment
                taxAmount: 0.00 // Not using 'taxAmount' at the moment
            },
            item: gtmItems
        }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gtmDL);

    return (
        <>
            <Container className="pt-4 pb-4">
                <Typography className="mt-0 mb-3" variant="h1">
                    Thanks for your booking!
                </Typography>
                <p className='mb-4'>We've sent a receipt along with your ticket details to your email address.</p>

                <Typography className="mt-0 mb-3 font-weight-bold" variant="h5">
                    Order Total: NZ${cost.toFixed(2)}
                </Typography>

                <hr className="mt-4 mb-4" />

                <Typography className="mb-2" variant="h3">
                    Itinerary Details
                </Typography>
                <p className="mb-5">Booking Reference: {bookingReference}</p>

                {map(cartItems, (item, key) => (
                    <ConfirmItem key={key} {...item} />
                ))}

                <p className="mt-4 mb-4">Your tickets will be sent to your email address.</p>

                <Link className={cx("btn btn-secondary d-inline-flex align-items-center", styles.accommodation)} to={process.env.GATSBY_BOOKING_COM_URL}>
                    Need Accommodation?
                </Link>
            </Container>

            {!!promoted_tours.length &&
            <div className={cx(styles.promoted, 'pt-4 pb-4')}>
                <Container>
                    <Typography className="mt-3" variant="h2" color="brand-color">
                        Customers Who Bought This Tour Also Bought
                    </Typography>

                    <TourCards>
                    {map(promoted_tours, (tour, idx) => (
                        <TourCard
                            key={idx}
                            {...tour}
                        />
                    ))}
                    </TourCards>
                </Container>
            </div>
            }
        </>
    )
}

export default Confirmation
